  @font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  }
  
  @font-face {
      font-family: 'Poppins';
      font-weight: 800;
      src: local('Poppins'), url(./assets/fonts/Poppins-Bold.ttf) format('truetype');
  }
  
  @font-face {
      font-family: 'Poppins';
      font-weight: 900;
      src: local('Poppins'), url(./assets/fonts/Poppins-Black.ttf) format('truetype');
  }